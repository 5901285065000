const loadScriptsTimer = setTimeout(loadScripts, parseInt(window.YETT_TIMEOUT))
const userInteractionEvents = [
  'mouseover',
  'keydown',
  'touchmove',
  'touchstart'
]
window.YETT_BLACKLIST.length && userInteractionEvents.forEach(function (event) {
  window.addEventListener(event, triggerScriptLoader, { passive: true })
})

function triggerScriptLoader () {
  loadScripts()
  clearTimeout(loadScriptsTimer)
  userInteractionEvents.forEach(function (event) {
    window.removeEventListener(event, triggerScriptLoader, { passive: true })
  })
}

function loadScripts () {
  window.yett.unblock()
}
